import globalAxios from 'axios';
import xhook from 'xhook';

const LOCAL_TOKEN_KEY = 'auth_token';
const HEADER_TOKEN_KEY = 'x-auth-token';

const axios = globalAxios.create({
    baseURL: process.env.VUE_APP_API,
    withCredentials: true,
});

const setLocalToken = token => localStorage.setItem(LOCAL_TOKEN_KEY, token);
const getLocalToken = () => localStorage.getItem(LOCAL_TOKEN_KEY);
const removeLocalToken = () => localStorage.removeItem(LOCAL_TOKEN_KEY);

const auth = {};

auth.login = (login, password) => axios.post('auth/login', {
    login,
    password,
}).then(({ data: { token  } }) => {
    setLocalToken(token);
});

auth.logout = async (ignoreServer = false) => {
    if (!ignoreServer) {
        await axios.post('auth/logout');
    }

    removeLocalToken();
};

auth.test = async () => {
    try {
        await axios.get('admin/security/test');

        return true;
    } catch (error) {
        return false;
    }
};

auth.isLoggedIn = () => !!getLocalToken();

xhook.before(function ({ headers }) {
    const localToken = getLocalToken();

    if (localToken) {
        headers.Authorization = `Bearer ${getLocalToken()}`;
    }
});

xhook.after(function (request, { headers }) {
    const newToken = headers[HEADER_TOKEN_KEY];

    if (newToken) {
        setLocalToken(newToken);
    }
});

export {auth, axios};
