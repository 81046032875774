import { auth } from '@/services/api';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import('@/layouts/MainLayout.vue'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@/views/HomeView.vue'),
                meta: {
                    title: 'Управление',
                }
            },
            {
                path: '/locations',
                name: 'locations',
                component: () => import('@/views/LocationsView.vue'),
                meta: {
                    title: 'Города',
                }
            },
            {
                path: '/locations/:id',
                name: 'location',
                component: () => import('@/views/LocationView.vue'),
                props: r => ({ id: Number(r.params.id) }),
                meta: {
                    title: 'Город',
                }
            },
            {
                path: '/question',
                name: 'question',
                component: () => import('@/views/QuestionView.vue'),
                meta: {
                    title: 'Вопросы и ответы',
                }
            },
            {
                path: '/documents',
                name: 'documents',
                component: () => import('@/views/DocumentsView.vue'),
                meta: {
                    title: 'Документы',
                }
            },
            {
                path: '/documents/:id',
                name: 'document',
                component: () => import('@/views/DocumentView.vue'),
                props: r => ({ id: Number(r.params.id) }),
                meta: {
                    title: 'Документ',
                }
            },
            {
                path: '/slider',
                name: 'slider',
                component: () => import('@/views/SliderView.vue'),
                meta: {
                    title: 'Слайдер',
                }
            },
            {
                path: '/news',
                name: 'news',
                component: () => import('@/views/NewsView.vue'),
                props: r => ({ pg: Number(r.query.pg ?? 1) }),
                meta: {
                    title: 'Новости',
                }
            },
            {
                path: '/notification',
                name: 'notification',
                component: () => import('@/views/NotificationView.vue'),
                props: r => ({ pg: Number(r.query.pg ?? 1) }),
                meta: {
                    title: 'Уведомления',
                }
            },
            {
                path: '/report',
                name: 'report',
                component: () => import('@/views/ReportView.vue'),
                props: r => ({ pg: Number(r.query.pg ?? 1) }),
                meta: {
                    title: 'Отчеты по уведомлениям',
                }
            },
            {
                path: '/report-order',
                name: 'report-order',
                component: () => import('@/views/ReportOrderView.vue'),
                props: r => ({ pg: Number(r.query.pg ?? 1) }),
                meta: {
                    title: 'Отчеты по заказам',
                }
            },
            {
                path: '/review',
                name: 'review',
                component: () => import('@/views/ReviewView.vue'),
                meta: {
                    title: 'Отзывы',
                }
            },
        ]
    },
    {
        path: '/',
        component: () => import('@/layouts/CenterLayout.vue'),
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import('@/views/LoginView.vue'),
                meta: {
                    title: 'Вход',
                }
            }
        ]
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    if (to.name == 'login') {
        return next();
    }

    if (!await auth.test()) {
        return next({ name: 'login' });
    }

    next();
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title ?? '';
    next();
});

export default router;
